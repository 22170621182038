import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link", "menu"];
  
  toggleMenu() {
    this.menuTarget.classList.toggle("hidden")
  }

  setActive(e){
    this.resetAll()
    e.target.classList.add("bg-blue-100");
    e.target.classList.remove("bg-transparent");
  }

  resetActive(e) {
    this.resetAll()
  }

  resetAll() {
    this.linkTargets.forEach((item) => {
      item.classList.remove("bg-blue-100");
      item.classList.add("bg-transparent");
    });
  }
}
