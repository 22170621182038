// because we can't get the progress of watching through the mux-callback, so we have track the progress with the mux-player event.
// so this stimulus controller is needed to achieve that requirement.
// this is the doc : https://docs.mux.com/guides/player-advanced-usage

// AND AGAIN THIS IS FOR TESTING, BECAUSE THE MUX-PLAYER EVENT should be implemented in FRONT END. WE CAN REMOVE LATER!

import { Controller } from "@hotwired/stimulus"
import '@mux/mux-player'

export default class extends Controller {
  static targets = ["player"]

  connect() {
    this.lastProgress = this.playerTarget.getAttribute('metadata-progress')
    this.playerTarget.addEventListener("timeupdate", this.updateProgress.bind(this))
    this.playerTarget.addEventListener("loadedmetadata", () => {
      this.playerTarget.currentTime = this.playerTarget.getAttribute('metadata-current-time')
    })
  }

  updateProgress(event) {
    const video = this.playerTarget
    const currentTime = video.currentTime
    const duration = video.duration
    const progress = (currentTime / duration) * 100

    // Only send updates when progress increases by 10% to reduce server load
    if (progress - this.lastProgress >= 10 || progress == 100) {
      this.lastProgress = progress

      // Send progress to backend
      this.sendProgress(duration, currentTime, progress)
    }
  }

  sendProgress(duration, currentTime, progress) {
    const url = this.playerTarget.getAttribute('metadata-send-progress-url')
    fetch(url, {
      method: 'PUT',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',  // Expect Turbo Stream response
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        current_time: currentTime,
        duration: duration,
        progress: progress
      })
    }).then(response => response.text())
      .then(Turbo.renderStreamMessage)
  }
}
