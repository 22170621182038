import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["days", "hours", "minutes", "delay"]

  connect() {
    this.populateFields()  // Populate the fields when the controller connects
    this.calculateDelay()  // Ensure the hidden delay field is also updated
  }

  initialize() {
    this.calculateDelay = this.calculateDelay.bind(this)
  }

  populateFields() {
    const totalSeconds = parseInt(this.delayTarget.value || 0, 10)

    const days = Math.floor(totalSeconds / 86400)
    const hours = Math.floor((totalSeconds % 86400) / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)

    this.daysTarget.value = days
    this.hoursTarget.value = hours
    this.minutesTarget.value = minutes
  }

  calculateDelay() {
    const days = parseInt(this.daysTarget.value || 0, 10)
    const hours = parseInt(this.hoursTarget.value || 0, 10)
    const minutes = parseInt(this.minutesTarget.value || 0, 10)

    const totalSeconds = (days * 86400) + (hours * 3600) + (minutes * 60)
    this.delayTarget.value = totalSeconds
  }

  change(event) {
    this.calculateDelay()
  }
}
