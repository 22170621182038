import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["videoSelect", "videoPreview"];

  connect() {
    const selectedOption = this.videoSelectTarget.options[this.videoSelectTarget.selectedIndex];
    this.showVideo(selectedOption)
  }

  preview(event) {
    const selectedOption = event.target.options[event.target.selectedIndex];
    this.showVideo(selectedOption)
  }

  showVideo(option) {
    const playbackId = option.getAttribute('data-playback-id');
    const title = option.text

    if (playbackId) {
      this.videoPreviewTarget.classList.remove('hidden');
      this.videoPreviewTarget.setAttribute('metadata-video-title', title)
      this.videoPreviewTarget.setAttribute('playback-id', playbackId);
    } else {
      this.videoPreviewTarget.classList.add('hidden');
      this.videoPreviewTarget.setAttribute('metadata-video-title', '')
      this.videoPreviewTarget.setAttribute('playback-id', '');
    }
  }
}
