import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["spinner", "form"]
  static values = {
    formId: String
  }

  connect() {
    // Set up variables for the typing timer and the delay interval
    this.typingTimer = null;
    this.doneTypingInterval = 500; // Time in ms (0.5 second)

    this.formTarget.addEventListener("turbo:submit-start", this.handleStart.bind(this));
    this.formTarget.addEventListener("turbo:submit-end", this.handleEnd.bind(this));
  }

  handleStart() {
    this.showSpinner()
  }

  handleEnd() {
    this.hideSpinner()
  }

  submit() {
    this.element.requestSubmit()
  }

  // Called when the user types in the input field
  typing() {
    // Clear the previous timer if the user is still typing
    clearTimeout(this.typingTimer);

    // Start a new timer that will trigger the save action after the delay
    this.typingTimer = setTimeout(() => this.doneTyping(), this.doneTypingInterval);
  }

  // Trigger the save action after the user has stopped typing
  doneTyping() {
    // Here, you can add your logic to save the input
    this.submit()
  }

  showSpinner() {
    if(this.spinnerTarget) {
      this.spinnerTarget.classList.remove("hidden")
    }
  }

  hideSpinner() {
    if(this.spinnerTarget) {
      this.spinnerTarget.classList.add("hidden")
    }
  }

  remove() {
    this.formTarget.remove()
  }
}

