import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["nameField", "dataTypeField"]

  connect() {
    console.log("CustomFieldsController connected");
  }

  selectPredefinedField(event) {
    const name = event.currentTarget.dataset.name;
    const dataType = event.currentTarget.dataset.dataType;
  console.log(event.currentTarget.dataset)

    this.nameFieldTarget.value = name;
    this.dataTypeFieldTarget.value = dataType;
  }
}
